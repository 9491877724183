<template>
    <div>
        <a-row>
            <a-col :span="12">
                <a-card title="新增轮播图">
                    <a-form-model :rules="rules" ref="ruleForm" layout="vertical" :model="form">
                        <a-form-model-item label="标题" prop="title">
                            <a-input v-model="form.title" placeholder="标题" />
                        </a-form-model-item>
                        <a-form-model-item label="封面" prop="image">
                            <Upload v-model="form.image" ref="upload" />
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button type="primary" @click="onSubmit">
                                提交
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </a-card>
                <a-table size="small" :data-source="carousels" :columns="columns" rowKey="id" bordered
                    :pagination="false">
                    <template slot="image" slot-scope="text,record">
                        <img :src="record.image" style="height:40px;" alt="">
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a-button-group>
                            <a-button icon="edit" size="small" type="primary" @click="handleEdit(record)">
                                编辑
                            </a-button>
                            <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="handleDel(record)">
                                <a-button icon="delete" size="small" type="danger">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </a-button-group>
                    </template>
                </a-table>
            </a-col>
            <a-col :span="12">
                <div>
                    <p style="text-align:center;font-size: 1.2rem;">
                        预览
                    </p>
                    <a-carousel :autoplay="true" :dots="true" arrows dots-class="slick-dots slick-thumb">
                        <a slot="customPaging" slot-scope="props">
                            <img :src="carousels[props.i].image" />
                        </a>
                        <div v-for="(img,index) in carousels" :key="index">
                            <img :src="img.image" />
                        </div>
                    </a-carousel>
                </div>

            </a-col>
        </a-row>

        <!-- 模态框 -->
        <a-modal v-model="visible" :width="900" :footer="null">
            <a-form-model ref="editRuleForm" :rules="rules" layout="vertical" :model="editForm">
                <a-form-model-item label="标题" prop="title">
                    <a-input v-model="editForm.title" placeholder="标题" />
                </a-form-model-item>
                <a-form-model-item label="封面" prop="image">
                    <Upload v-model="editForm.image" ref="upload" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="updateSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>
<script>
import Upload from '@/components/Upload'
const rules = {
    title: [
        { required: true, message: '标题不能为空', trigger: 'change' },
    ],
    image: [{ required: true, message: '请上传图片', trigger: 'change' },],
}
const columns = [
    {
        title: 'id',
        key: 'id',
        dataIndex: 'id',
    },

    {
        title: '标题',
        key: 'title',
        dataIndex: 'title',
    },
    {
        title: '预览图',
        key: 'image',
        dataIndex: 'image',
        scopedSlots: { customRender: 'image' },
    },
    {
        title: '创建时间',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
    },
    {
        title: "操作",
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
    }
];
export default {
    components: { Upload },
    data() {
        return {
            rules,
            form: {},
            editForm: {
                id: null,
                title: null,
                image: ''
            },
            carousels: [],
            columns,
            visible: false,
        };
    },
    created() {
        this.getCarousels()
    },
    methods: {
        async getCarousels() {
            this.carousels = await this.axios.get(`admin/carousel`)
        },
        async onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    await this.axios.post("admin/carousel", this.form).then(_ => {
                        this.$refs.ruleForm.resetFields();
                        this.getCarousels()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async updateSubmit() {
            this.$refs.editRuleForm.validate(async valid => {
                if (valid) {
                    await this.axios.put(`admin/carousel/${this.editForm.id}`, this.editForm).then(_ => {
                        this.getCarousels()
                        this.visible = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async handleDel(c) {
            await this.axios.delete(`admin/carousel/${c.id}`).then(() => {
                this.carousels.splice(this.carousels.indexOf(c), 1)
            })
        },
        handleEdit(record) {
            this.visible = true
            this.$set(this.editForm, 'id', record.id)
            this.$set(this.editForm, 'title', record.title)
            this.$set(this.editForm, 'image', record.image)

        }
    },
};
</script>
<style scoped>
/* For demo */
.ant-carousel>>>.slick-dots {
    height: auto;
}

.ant-carousel>>>.slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 80%;
}

.ant-carousel>>>.slick-thumb {
    bottom: -45px;
}

.ant-carousel>>>.slick-thumb li {
    width: 60px;
    height: 45px;
}

.ant-carousel>>>.slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

.ant-carousel>>>.slick-thumb li.slick-active img {
    filter: grayscale(0%);
}
</style>